// Service worker

const CACHE_NAME = 'cache-v1';
const urlsToCache = [
  '/',
  '/index.html'
];

self.addEventListener("install", event => {
  event.waitUntil(
    caches.open(CACHE_NAME)
      .then(cache => {
        return cache.addAll(urlsToCache);
      }).then(self.skipWaiting())
  );
});
self.addEventListener("activate", event => {
  const cacheWhitelist = [CACHE_NAME];
  event.waitUntil(
    caches.keys().then(cacheNames => {
        return Promise.all(
          cacheNames.map(cacheName => {
            if (cacheWhitelist.indexOf(cacheName) === -1) {
              return caches.delete(cacheName);
            }
          })
        );
      }
    ).then(()=>self.clients.claim())
  );
});

self.addEventListener("fetch", event => {
  event.respondWith(
    caches.open(CACHE_NAME)
      .then(cache => {
          return cache.match(event.request)
            .then(response => {
                return response || fetch(event.request)
                  .then(response => {
                      cache.put(event.request, response.clone()).then(r => {
                        console.log(r);
                      });
                      return response;
                    }
                  );
              }
            );
        }
      )
  );
});
